import { Currency } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"
import { Money } from "@commercetools/platform-sdk"
import { None, Option } from "funfix-core"
import { StockLevel } from "./models/DetailsPageVariant"
import { ListingPageVariant } from "./models/ListingPageVariant"
import { SimpleAsset } from "./models/SimpleAsset"
import { Recommendation, TypedRecommendation } from "./Recommendations"
import {
  ColorTerm,
  KeyAndLabel,
  ModelDetail,
  Size,
  Style,
  KeyAndValue
} from "./VariantProxy"
import { VariantProxyI, variantAsset, SKU } from "./VariantProxyI"

export class ListingVariantProxy implements VariantProxyI {
  private variant: ListingPageVariant

  constructor(variant: ListingPageVariant) {
    this.variant = variant
  }

  public detailDescription(language: Language): Option<string> {
    return None
  }

  public colorCode(): Option<string> {
    return Option.of(this.variant.colorCode)
  }

  public colorHex(): Option<string> {
    return Option.of(this.variant.colorHexcode)
  }

  public colorName(language: Language): Option<string> {
    return Option.of(this.variant.colorName).flatMap(it =>
      Option.of(it[language])
    )
  }

  public colorTerm(): Option<ColorTerm> {
    return Option.of(this.variant.colorTerm)
  }

  public colorTermKey(): Option<string> {
    return this.colorTerm().map(it => it.key)
  }

  public colorTermName(language: Language): Option<string> {
    return this.colorTerm().flatMap(it => Option.of(it.label[language]))
  }

  public discountedPrice(currency): Option<Money> {
    return Option.of(this.variant.prices)
      .flatMap(prices =>
        Option.of(prices.find(it => it.value.currencyCode === currency))
      )
      .flatMap(it => Option.of(it.discounted).map(dp => dp.value))
  }

  public functionality(language: Language): KeyAndValue[] {
    return (this.variant.functionality || []).map(it => ({
      key: it.key,
      value: it.label[language]
    }))
  }

  public modelDetails(): ModelDetail[] {
    return []
  }

  public functionalityKey(): Option<string[]> {
    return Option.of((this.variant.functionality || []).map(it => it.key))
  }

  public functionalityObj(): Option<KeyAndLabel[]> {
    return Option.of(this.variant.functionality || [])
  }

  public phoneticSpelling(): string {
    return ""
  }

  public garmentComposition(language): { name: string; value: string }[] {
    return []
  }

  public getAllProductRecommendations(
    language: Language
  ): TypedRecommendation[] {
    return []
  }

  public asset(): Option<SimpleAsset> {
    return variantAsset(this)
  }

  public imageUrl(): Option<string> {
    return this.asset().map(it => it.url)
  }

  get isInStock(): boolean {
    return this.stockLevel !== "out-of-stock"
  }

  get stockLevel(): StockLevel {
    return this.variant.availability
  }

  public layering(language: Language): KeyAndValue[] {
    return []
  }

  public listingDescription(language: Language): Option<string> {
    return Option.of(this.variant.listingDescription).flatMap(it =>
      Option.of(it[language])
    )
  }

  public price(currency: Currency): Option<Money> {
    return Option.of(this.variant.prices)
      .flatMap(prices =>
        Option.of(prices.find(it => it.value.currencyCode === currency))
      )
      .map(it => it.value)
  }

  public assets(): SimpleAsset[] {
    return this.variant.assets || []
  }

  public shells(language): KeyAndValue[] {
    return []
  }

  public size(): Option<Size> {
    return Option.of(this.variant.size)
  }

  public sizeKey(): Option<string> {
    return this.size().map(it => it.key)
  }

  public sizeName(language): Option<string> {
    return this.size().flatMap(it => Option.of(it.label[language]))
  }

  public sku(): SKU {
    return (this.variant.sku || "") as SKU
  }

  public style(): Option<Style> {
    return Option.of(this.variant.style)
  }

  public styleKey(): Option<string> {
    return this.style().map(it => it.key)
  }

  public styleName(language: Language): Option<KeyAndValue> {
    return this.style().flatMap(it =>
      Option.of({
        key: it.key,
        value: it.label[language]
      })
    )
  }

  public suitableFor(language: Language): string[] {
    return []
  }

  public tpdSuitableFor(language: Language): KeyAndValue[] {
    return []
  }

  public tpdWashingInstructions(language): KeyAndValue[] {
    return []
  }

  public variantAvailabilityIs(it: ListingPageVariant, availability: string) {
    return false
  }

  public getAlternativeRecommendations(language: Language): Recommendation[] {
    return []
  }

  public getLiwRecommendations(language: Language): Recommendation[] {
    return []
  }

  public getWiwRecommendations(language: Language): Recommendation[] {
    return []
  }
}
