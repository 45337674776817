import {
  Attribute,
  LocalizedString,
  Product,
  ChannelReference,
  ProductProjection
} from "@commercetools/platform-sdk"
import { Language } from "@sixty-six-north/i18n"
import { Store } from "cart/Stores"
import { Option } from "funfix-core"
import { notUndefined } from "../prismic/PrismicIdAggregator"
import { filterTruthy } from "../utils/Filter"
import logger from "../utils/logger"
import { hierarchicalProductHasAnyStock } from "../utils/ProductUtils"
import { DomainCategory } from "./models/DomainCategory"
import {
  BasicColorway,
  CoreProductInformation
} from "./models/DetailedProductInformation"
import { AllCategories } from "./ProductDal"
import {
  transform,
  basicMappingStrategy,
  ProductMappingStrategy
} from "./ProductReduction"
import { attributeValue } from "./VariantProxy"

export interface Recommendation {
  badge: string
  product: CoreProductInformation
}

interface RecommendedProduct extends Recommendation {
  categorySlug: string
  parentCategories?: DomainCategory[]
}

interface RecommendedLook {
  title: string
  products: RecommendedProduct[]
}

export interface RecommendedLooks {
  looks: RecommendedLook[]
}

interface RecommendedProduct extends Recommendation {
  categorySlug: string
  parentCategories?: DomainCategory[]
}

export interface TypedRecommendation {
  type: string
  recommendations: Recommendation[]
}

interface RecommendationWithReason {
  reason: LocalizedString
  product: CoreProductInformation
}

const getRecommendationsFor = (
  recommendations: RecommendationWithReason[],
  language: string
): Recommendation[] => {
  return recommendations
    .map(recommendation => {
      const product: CoreProductInformation = recommendation.product

      return {
        product,
        badge: recommendation.reason[language]
      }
    })
    .filter(notUndefined)
}

export function attributesToRecommendations(
  name: string,
  attributes: Attribute[],
  mappingStrategy: ProductMappingStrategy<
    BasicColorway,
    CoreProductInformation
  >,
  store?: Store
): TypedRecommendation {
  const recommendations = getRecommendationsFor(
    createRecommendationsWithReason(
      attributeValue<Attribute[][]>(
        `${name}-product-recommendations`,
        attributes
      ) || [],
      mappingStrategy
    ) || [],
    store?.language || Language.en
  )
  return { type: name, recommendations }
}

export function attributesToRecommendations2(
  name: string,
  recommendationsValue: Attribute[][],
  mappingStrategy: ProductMappingStrategy<
    BasicColorway,
    CoreProductInformation
  >,
  language: string
): TypedRecommendation {
  const recommendations = getRecommendationsFor(
    createRecommendationsWithReason(
      recommendationsValue || [],
      mappingStrategy
    ) || [],
    language || Language.en
  )
  return { type: name, recommendations }
}

export const createRecommendations = (
  attributes: Attribute[],
  store?: Store
): TypedRecommendation[] => {
  const mappingStrategy = basicMappingStrategy(store!)
  return [
    attributesToRecommendations("wiw", attributes, mappingStrategy, store),
    attributesToRecommendations("liw", attributes, mappingStrategy, store),
    attributesToRecommendations(
      "alternatives",
      attributes,
      mappingStrategy,
      store
    )
  ].filter(it => it.recommendations.length > 0)
}

function productToProjection(product: Product): ProductProjection {
  return {
    id: product.id,
    key: product.key,
    version: product.version,
    createdAt: product.createdAt,
    lastModifiedAt: product.lastModifiedAt,
    productType: product.productType,
    ...product.masterData.current
  }
}

export const createRecommendationsWithReason = (
  value: Attribute[][],
  mappingStrategy: ProductMappingStrategy<BasicColorway, CoreProductInformation>
): RecommendationWithReason[] => {
  return value.flatMap(recommendation => {
    const reason = Option.of(
      recommendation.find(it => it.name === "recommendation-reason")
    ).map(it => it.value as LocalizedString)

    const product = Option.of(
      recommendation.find(it => it.name === "recommended-product")
    ).flatMap(it => {
      if (it && it.value && it.value.id && it.value.typeId) {
        return Option.some(
          it.value as { obj: Product; id: string; typeId: string }
        )
      } else {
        return Option.none()
      }
    })

    return reason
      .flatMap(reason => product.map(product => ({ reason, product })))
      .filter(it => it?.product?.obj?.masterData?.published)
      .map(it => {
        return {
          reason: it.reason,
          product: transform(
            productToProjection(it.product.obj),
            mappingStrategy
          )
        }
      })
      .map(it => [it])
      .getOrElse([])
  })
}

const mapProductRecommendation = (
  { product: p, ...rest }: RecommendedProduct,
  store?,
  category?: DomainCategory
): RecommendedProduct => {
  return {
    ...rest,
    product: p
  }
}

export type IsAvailableIn = (productChannels: ChannelReference[]) => boolean

const extractRecommendedProducts = (
  recommendations: Recommendation[],
  categories: AllCategories,
  currentCategory: DomainCategory | undefined,
  language: string,
  isAvailableIn: IsAvailableIn
): RecommendedProduct[] => {
  const products: RecommendedProduct[] = []
  const categoriesWithSameAncestorAsCurrentCat =
    categories.withSharedParent(currentCategory)
  recommendations.forEach(recommendation => {
    if (!hierarchicalProductHasAnyStock(recommendation.product)) return

    const salesChannels = Object.values(
      recommendation.product.colorways
    ).flatMap(it => it.salesChannels)

    const availableForSale = isAvailableIn(salesChannels)

    if (availableForSale) {
      const recommendationCategory =
        categoriesWithSameAncestorAsCurrentCat.find(category => {
          return (
            recommendation.product.categories.find(
              it => it.id === category.id
            ) !== undefined
          )
        })

      const slug = recommendationCategory?.slug[language]
      if (slug !== undefined) {
        products.push({
          product: recommendation.product,
          badge: recommendation.badge,
          categorySlug: slug,
          parentCategories:
            currentCategory?.ancestors.map(it => it)?.filter(filterTruthy) || []
        })
      }
    }
  })

  return products.slice(0, 3)
}

export const checkProductIsAvailableInDistributionChannel: (
  distributionChannels?: ChannelReference[]
) => IsAvailableIn = distributionChannels => {
  const setOfChannelIds = (channels: ChannelReference[]) =>
    new Set(channels.map(it => it.id))
  const distributionChannelIds = [
    ...setOfChannelIds(distributionChannels || [])
  ]
  return (productChannels: ChannelReference[]) => {
    const productChannelIds = setOfChannelIds(productChannels)
    return (
      distributionChannelIds.filter(it => productChannelIds.has(it)).length > 0
    )
  }
}

export const transformRecommendedProductsCollection = (
  recommendations: TypedRecommendation[],
  store: Store,
  category: DomainCategory | null,
  categories: AllCategories,
  isAvailable: IsAvailableIn
): RecommendedLooks[] => {
  try {
    const currentCategory = category || categories[0]
    const emptyLook = [
      {
        title: "",
        products: []
      }
    ]

    if (!recommendations || !currentCategory) {
      return []
    }

    if (recommendations?.length > 0) {
      const looks: RecommendedLook[] = []

      recommendations.forEach(recommendation => {
        const products: RecommendedProduct[] = extractRecommendedProducts(
          recommendation?.recommendations,
          categories,
          currentCategory,
          store.language,
          isAvailable
        )

        if (products.length > 0) {
          const mappedProducts = products.map(p => mapProductRecommendation(p))
          looks.push({
            title: recommendation?.type || "",
            products: mappedProducts
          })
        }
      })

      if (looks.length > 0) {
        return [{ looks: looks.concat(emptyLook) }] // TODO: Remove Concat empty look?
      }
    }
  } catch (e) {
    logger.info("Error transforming recommendations for product", e)
  }
  return []
}
