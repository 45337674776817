import {
  Cart,
  CartDraft,
  ClientResponse,
  DiscountCodeReference,
  ShippingMethod,
  UpdateAction,
  MyCartUpdateAction,
  CartAddLineItemAction
} from "@commercetools/platform-sdk"
import { ByProjectKeyRequestBuilder } from "@commercetools/platform-sdk/dist/declarations/src/generated/client/by-project-key-request-builder"
import { ByProjectKeyMeCartsByIDRequestBuilder } from "@commercetools/platform-sdk/dist/declarations/src/generated/client/carts/by-project-key-me-carts-by-id-request-builder"
import { ApiRequest } from "@commercetools/platform-sdk/dist/declarations/src/generated/shared/utils/requests-utils"
import * as Sentry from "@sentry/react"
import { Action, Dispatch } from "redux"
import { getLocalStorageCart } from "utils/CartUtils"
import {
  CommerceToolsClient,
  ServerSideCommerceToolsClientI
} from "../commercetools/CommerceToolsClient"
import {
  Address,
  DynamicUpdateAction
} from "../models/CommerceToolsModelExtensions"
import { SKU } from "../product/VariantProxyI"
import { Postbox } from "../shipping/IcelandPostbox"
import { createLogger } from "../utils/createLogger"
import { CartState } from "./CartState"
import { Store } from "./Stores"

const logger = createLogger("CartService")

interface VatId {
  vatId?: string
}

export type ExtendedAddress = Address & VatId

function includeIf<T>(condition: boolean, ...validators: T[]): T[] {
  return condition ? validators : []
}

export class CartService {
  private readonly client:
    | CommerceToolsClient
    | ServerSideCommerceToolsClientI
    | undefined
  public store: Store
  private readonly dispatch: Dispatch<Action>

  private cartQueryArgs = {
    queryArgs: {
      expand: [
        "discountCodes[*].discountCode",
        "lineItems[*].price.discounted.discount",
        "shippingInfo.shippingMethod",
        "paymentInfo.payments[*]"
      ]
    }
  }

  constructor(
    client: CommerceToolsClient | ServerSideCommerceToolsClientI,
    store: Store,
    dispatch: Dispatch<Action>
  ) {
    this.client = client
    this.dispatch = dispatch
    this.store = store
  }

  public setStore = (store: Store) => {
    this.store = store
  }

  public getOrCreateCart = async (c?: Cart): Promise<Cart | undefined> => {
    return this.getActiveCart()
      .catch(async err => {
        logger.warn("Active cart not found, creating a blank one", err)
        return this.createCartFromData(c).catch(() => {
          return this.createCartFromData(undefined)
        })
      })
      .catch(err => {
        logger.error("Failed creating cart. Token may be invalid", err)
        Sentry.captureMessage("Failed creating cart. Token may be invalid", {
          extra: {
            err
          },
          level: "warning"
        })

        this.client?.logout()
        return undefined
      })
  }

  public fetch = async (cartId: string): Promise<Cart | undefined> => {
    return this.withClient(it =>
      it
        .inStoreKeyWithStoreKeyValue({
          storeKey: this.store.name
        })
        .me()
        .carts()
        .withId({ ID: cartId })
        .get({ ...this.cartQueryArgs })
    ).then(response => response.body)
  }

  public addLineItem = (item: {
    sku: string
    quantity: number
  }): Promise<Cart> => {
    const { sku, quantity } = item
    return this.runActions([
      {
        action: "addLineItem",
        sku,
        quantity
      } as CartAddLineItemAction
    ])
  }

  public removeLineItem = (item: {
    lineItemId: string
    quantity: number
  }): Promise<Cart> => {
    return this.runActions([
      {
        action: "removeLineItem",
        lineItemId: item.lineItemId,
        quantity: item.quantity
      } as DynamicUpdateAction
    ])
  }

  public removeAllOfLineItem = (item: {
    lineItemId: string
  }): Promise<Cart> => {
    return this.runActions([
      {
        action: "removeLineItem",
        lineItemId: item.lineItemId
      } as DynamicUpdateAction
    ])
  }

  public replaceLineItem = (
    remove: { lineItemId: string },
    add: {
      sku: string
      quantity: number
    }
  ): Promise<Cart> => {
    return this.runActions([
      {
        action: "removeLineItem",
        lineItemId: remove.lineItemId
      } as DynamicUpdateAction,
      {
        action: "addLineItem",
        sku: add.sku,
        quantity: add.quantity
      } as DynamicUpdateAction
    ])
  }

  public addDiscountCode = (code: string): Promise<Cart> => {
    return this.runActions([
      {
        action: "addDiscountCode",
        code
      } as DynamicUpdateAction
    ])
  }

  public removeDiscountCode = (
    reference: DiscountCodeReference
  ): Promise<Cart> => {
    return this.runActions([
      {
        action: "removeDiscountCode",
        discountCode: { typeId: reference.typeId, id: reference.id }
      } as DynamicUpdateAction
    ])
  }
  public setBillingAddress = (
    address: ExtendedAddress
  ): Promise<Cart | undefined> => this.runActions(this.billingAddressUpdates(address))

  private billingAddressUpdates(address?: ExtendedAddress) {
    return address ? [
      {
        action: "setBillingAddress",
        address
      } as DynamicUpdateAction,
      ...includeIf(!!address.vatId, {
        action: "setBillingAddressCustomType",
        type: {
          key: "address-data",
          typeId: "type"
        },
        fields: {
          "vat-id": address.vatId
        }
      })
    ] : []
  }

  public setShippingAddress = (address: ExtendedAddress) => this.runActions(this.shippingAddressUpdates(address))

  private shippingAddressUpdates(address?: ExtendedAddress): UpdateAction[] {
    return address ? [
      {
        action: "setShippingAddress",
        address
      } as DynamicUpdateAction,
      ...includeIf(!!address.vatId, {
        action: "setShippingAddressCustomType",
        type: {
          key: "address-data",
          typeId: "type"
        },
        fields: {
          "vat-id": address.vatId
        }
      })
    ] : []
  }

  public update = (updates: {shippingAddress?: ExtendedAddress, billingAddress?: ExtendedAddress, customerEmail?: string}): Promise<Cart> => {
    const {shippingAddress, billingAddress, customerEmail} = updates
    const actions: UpdateAction[] = [
        ...includeIf(!!customerEmail, ...this.customerEmailUpdates(customerEmail)),
        ...this.shippingAddressUpdates(shippingAddress),
        ...this.billingAddressUpdates(billingAddress),
    ]

    return this.runActions(actions)
  }

  public setShippingMethod = (shippingMethod: ShippingMethod) => {
    return this.setShippingMethodId(shippingMethod.id)
  }

  public setShippingMethodId = (shippingMethodId: string) => {
    return this.runActions([
      {
        action: "setShippingMethod",
        shippingMethod: { id: shippingMethodId }
      } as DynamicUpdateAction
    ])
  }

  public setFraudScore(
    cart: Cart,
    fraudData: { "fraud-description": string; "fraud-score": string }
  ) {
    return this.setOrderCustomTypeDataOnCart(cart, fraudData)
  }

  private setOrderCustomTypeDataOnCart(
    cart: Cart,
    fields: Record<string, unknown>
  ): Promise<Cart> {
    return this.runActionsForCart(cart, [
      {
        action: "setCustomType",
        type: {
          key: "order-data",
          typeId: "type"
        },
        fields: {
          ...cart.custom?.fields,
          ...fields
        }
      } as DynamicUpdateAction
    ])
  }

  public setOrderCustomTypeData = (
    fields: Record<string, unknown>
  ): Promise<Cart> =>
    this.getActiveCart().then(cart =>
      this.setOrderCustomTypeDataOnCart(cart, fields)
    )

  public setEmailAddress = (email?: string) => {
    return this.runActions(this.customerEmailUpdates(email))
  }

  private customerEmailUpdates(email?: string): UpdateAction[] {
    return [
      {
        action: "setCustomerEmail",
        email
      } as DynamicUpdateAction
    ]
  }

  public setPostbox = async (
    postbox: Postbox | undefined
  ): Promise<Cart | undefined> => {
    return this.getActiveCart().then(cart => {
      if (cart?.shippingAddress) {
        const postboxData =
          postbox === undefined
            ? {
                externalId: undefined,
                pOBox: undefined,
                additionalAddressInfo: undefined
              }
            : {
                externalId: postbox?.postboxId,
                pOBox: postbox?.name,
                additionalAddressInfo: postbox?.name
              }
        return this.setShippingAddress({
          ...cart.shippingAddress,
          ...postboxData
        } as ExtendedAddress)
      } else {
        return Promise.resolve(cart)
      }
    })
  }

  async setDroppLocation(
    droppLocation: DroppLocation
  ): Promise<Cart | undefined> {
    return this.getActiveCart().then(cart => {
      if (cart?.shippingAddress) {
        const postboxData =
          droppLocation === undefined
            ? {
                externalId: undefined,
                pOBox: undefined,
                additionalAddressInfo: undefined
              }
            : {
                externalId: droppLocation.id,
                pOBox: undefined,
                additionalAddressInfo: droppLocation.externalLocationId
              }
        return this.setShippingAddress({
          ...cart.shippingAddress,
          ...postboxData
        } as ExtendedAddress)
      } else {
        return Promise.resolve(cart)
      }
    })
  }

  public fullCart = async (id: string) => {
    return this.withClient(it =>
      it.me().carts().withId({ ID: id }).get(this.cartQueryArgs)
    ).then(it => it.body)
  }

  public deleteActiveCart = async () => {
    return this.withClient(it =>
      it
        .inStoreKeyWithStoreKeyValue({
          storeKey: this.store.name
        })
        .me()
        .activeCart()
        .get()
    ).then(({ body: { id, version } }) =>
      this.withCart<Cart>(id, it => it.delete({ queryArgs: { version } }))
    )
  }

  public refreshCart = () => {
    return this.getOrCreateCart()
  }

  public fetchCartWithId = async (id: string): Promise<Cart> =>
    (await this.withClient(it => it.me().carts().withId({ ID: id }).get())).body

  private getActiveCart = async (): Promise<Cart> => {
    return this.withClient(it =>
      it
        .inStoreKeyWithStoreKeyValue({
          storeKey: this.store.name
        })
        .me()
        .activeCart()
        .get({ ...this.cartQueryArgs })
    ).then(it => this.dispatch(CartState.actions.setCart(it.body)).payload)
  }

  private createCartFromData = async (c?: Cart): Promise<Cart> => {
    const cartData = (c || getLocalStorageCart() || {}) as CartDraft
    return this.createCart(cartData).then(
      cart => this.dispatch(CartState.actions.setCart(cart)).payload
    )
  }

  private withClient = <T>(
    fn: (it: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>> => {
    if (this.client) {
      return this.client.execute(fn)
    } else {
      return Promise.reject("No commerce tools client")
    }
  }

  private withCart = <T>(
    cartId: string,
    fn: (client: ByProjectKeyMeCartsByIDRequestBuilder) => ApiRequest<T>
  ) => {
    return this.withClient(it => fn(it.me().carts().withId({ ID: cartId })))
  }

  private runActions = async (
    actions: DynamicUpdateAction[]
  ): Promise<Cart> => {
    return this.getOrCreateCart().then(cart => {
      if (cart) {
        return this.runActionsForCart(cart, actions)
      } else {
        throw Error("Cart missing")
      }
    })
  }

  private runActionsForCart = (
    cart: Cart,
    actions: UpdateAction[]
  ): Promise<Cart> =>
    this.withCart<Cart>(cart.id, it =>
      it.post({
        ...this.cartQueryArgs,
        body: {
          version: cart.version,
          actions: actions as MyCartUpdateAction[]
        }
      })
    ).then(it => this.dispatch(CartState.actions.setCart(it.body)).payload)

  private createCart(cartData: CartDraft): Promise<Cart> {
    return this.withClient(it =>
      it
        .inStoreKeyWithStoreKeyValue({
          storeKey: this.store.name
        })
        .me()
        .carts()
        .post({
          body: {
            ...cartData,
            country: this.store.countries[0].toUpperCase(),
            currency: this.store.currency,
            locale: this.store.language
          }
        })
    ).then(it => it.body)
  }

  public createExpressCart(sku: SKU): Promise<Cart> {
    return this.withClient(it =>
      it
        .inStoreKeyWithStoreKeyValue({
          storeKey: this.store.name
        })
        .carts()
        .post({
          body: {
            currency: this.store.currency.toUpperCase(),
            country: this.store.countries[0].toUpperCase(),
            locale: this.store.language,
            origin: "Merchant",
            lineItems: [
              {
                quantity: 1,
                sku
              }
            ],
            deleteDaysAfterLastModification: 1
          }
        })
    ).then(it => it.body)
  }
}
