import {
  Attribute,
  LocalizedString,
  Money,
  ProductVariant
} from "@commercetools/platform-sdk"
import { Language } from "@sixty-six-north/i18n"
import { equals, Option } from "funfix-core"
import _isArray from "lodash/isArray"
import _isEmpty from "lodash/isEmpty"
import _isObject from "lodash/isObject"
import { Stores } from "../cart/Stores"
import { notUndefined } from "../prismic/PrismicIdAggregator"
import { DetailVariantProxy } from "./DetailVariantProxy"
import { ListingVariantProxy } from "./ListingVariantProxy"
import { DetailsPageVariants, StockLevel } from "./models/DetailsPageVariant"
import { DomainVariant, isDetailsVariant } from "./models/DomainVariant"
import { SimpleAsset } from "./models/SimpleAsset"
import { TypedRecommendation } from "./Recommendations"
import { VariantProxyI, SKU } from "./VariantProxyI"

export interface KeyAndLabel {
  key: string
  label: LocalizedString
}

export interface KeyAndValue {
  key: string
  value: string
}

export interface NameAndValue {
  name: string
  value: LocalizedString
}

export type Size = KeyAndLabel
export type Style = KeyAndLabel
export type ColorTerm = KeyAndLabel
export interface ModelDetailAttribute {
  name: string
  value: number | string
}

export interface Length {
  centimetres: number
  totalInches: number
  feet: number
  inches: number
}

export interface ModelDetail {
  model: "maleModel" | "femaleModel"
  height: Length | null
  chest: Length | null
  waist: Length | null
  size: string | null
}

export type GarmentComposition =
  | { name: string; value: LocalizedString }
  | { name: string; value: KeyAndLabel }
  | { name: string; value: KeyAndLabel[] }

export class VariantProxy implements VariantProxyI {
  get isInStock(): boolean {
    return this.proxy.isInStock
  }

  get stockLevel(): StockLevel {
    return this.proxy.stockLevel
  }

  public static fromProductVariant(variant: ProductVariant): VariantProxy {
    return new VariantProxy(
      DetailsPageVariants.fromProductVariant(variant, Stores.UK)
    )
  }

  public variant: DomainVariant
  private proxy: VariantProxyI

  constructor(variant: DomainVariant) {
    this.variant = variant

    if (isDetailsVariant(variant)) {
      this.proxy = new DetailVariantProxy(variant)
    } else {
      this.proxy = new ListingVariantProxy(variant)
    }
  }

  public variantAvailabilityIs(
    variant: DomainVariant,
    availability: string
  ): boolean {
    return this.proxy.variantAvailabilityIs(variant, availability)
  }

  public colorCode(): Option<string> {
    return this.proxy.colorCode()
  }

  public colorHex(): Option<string> {
    return this.proxy.colorHex()
  }

  public colorName(language): Option<string> {
    return this.proxy.colorName(language)
  }

  public colorTerm(): Option<ColorTerm> {
    return this.proxy.colorTerm()
  }

  public colorTermKey(): Option<string> {
    return this.proxy.colorTermKey()
  }

  public colorTermName(language): Option<string> {
    return this.proxy.colorTermName(language)
  }

  public detailDescription(language): Option<string> {
    return this.proxy.detailDescription(language)
  }

  public discountedPrice(currency): Option<Money> {
    return this.proxy.discountedPrice(currency)
  }

  public functionality(language): KeyAndValue[] {
    return this.proxy.functionality(language)
  }

  public functionalityKey(): Option<string[]> {
    return this.proxy.functionalityKey()
  }

  public functionalityObj(): Option<KeyAndLabel[]> {
    return this.proxy.functionalityObj()
  }

  public garmentComposition(language): { name: string; value: string }[] {
    return this.proxy.garmentComposition(language)
  }

  public phoneticSpelling(): string {
    return this.proxy.phoneticSpelling()
  }

  public getAllProductRecommendations(
    language: Language
  ): TypedRecommendation[] {
    return this.proxy.getAllProductRecommendations(language)
  }

  public asset(): Option<SimpleAsset> {
    return this.proxy.asset()
  }

  public imageUrl(): Option<string> {
    return this.proxy.imageUrl()
  }

  public layering(language): KeyAndValue[] {
    return this.proxy.layering(language)
  }

  public listingDescription(language): Option<string> {
    return this.proxy.listingDescription(language)
  }

  public price(currency): Option<Money> {
    return this.proxy.price(currency)
  }

  public shells(language): KeyAndValue[] {
    return this.proxy.shells(language)
  }

  public size(): Option<Size> {
    return this.proxy.size()
  }

  public sizeKey(): Option<string> {
    return this.proxy.sizeKey()
  }

  public sizeName(language): Option<string> {
    return this.proxy.sizeName(language)
  }

  public sku(): SKU {
    return this.proxy.sku()
  }

  public inventoryURL(store: string = "is"): string {
    return `/by-sku/${encodeURIComponent(
      this.sku()
    )}/inventory/by-store?store=${store}`
  }

  public style(): Option<Style> {
    return this.proxy.style()
  }

  public styleKey() {
    return this.proxy.styleKey()
  }

  public styleName(language): Option<KeyAndValue> {
    return this.proxy.styleName(language)
  }

  public suitableFor(language): string[] {
    return this.proxy.suitableFor(language)
  }

  public tpdSuitableFor(language): KeyAndValue[] {
    return this.proxy.tpdSuitableFor(language)
  }

  public tpdWashingInstructions(language): KeyAndValue[] {
    return this.proxy.tpdWashingInstructions(language)
  }

  public modelDetails(): ModelDetail[] {
    return this.proxy.modelDetails()
  }

  public modelDetailsFor(genders: string[]): ModelDetail[] {
    const modelSexForGenders = this.modelSexFor(genders)

    return this.proxy
      .modelDetails()
      .filter(modelDetail => modelSexForGenders.includes(modelDetail.model))
  }

  private modelSexFor(genders: string[]) {
    const modelSexForGenders: string[] = (
      genders || ["femaleModel", "maleModel"]
    ).flatMap(gender => {
      if (gender === "women") {
        return "femaleModel"
      } else if (gender === "men") {
        return "maleModel"
      } else {
        return ["femaleModel", "maleModel"]
      }
    })

    return _isEmpty(modelSexForGenders)
      ? ["femaleModel", "maleModel"]
      : modelSexForGenders
  }

  public assets(): SimpleAsset[] {
    return this.proxy.assets()
  }
}

export function centimetresToLength(
  v: number | string | undefined
): Length | undefined {
  const value = Number.parseInt(v as unknown as string, 10)
  if (Number.isNaN(value)) {
    return undefined
  }
  const totalInches = parseInt(
    Math.floor((value as number) * 0.393700787).toFixed(0),
    10
  )
  return {
    centimetres: value as number,
    totalInches,
    feet: Math.floor(totalInches / 12),
    inches: totalInches % 12
  }
}

export const attributeValue = <T>(
  name: string,
  attributes: Attribute[] | undefined
): T | undefined => {
  return Option.of((attributes || []).filter(it => it.name === name)[0])
    .map(it => it.value as T)
    .getOrElse(undefined)
}
export const attributeValues = <T>(
  name: string,
  attributes: Attribute[] | undefined
): T[] | undefined => {
  return Option.of(
    (attributes || [])
      .filter(it => equals(name, it.name))
      .flatMap(it => it.value as T)
  ).getOrElse(undefined)
}

export const getGarmentCompositionText = (
  obj: LocalizedString | KeyAndLabel | KeyAndLabel[]
): LocalizedString[] => {
  if (_isArray(obj)) {
    return obj.flatMap(getGarmentCompositionText)
  } else if (isKeyAndLabel(obj)) {
    return [obj.label]
  } else if (isLocalizedString(obj)) {
    return [obj]
  } else {
    return []
  }
}

export const getGarmentCompositionValue = (
  name: string,
  composition: GarmentComposition[],
  language: Language
): string[] => {
  return composition
    .filter(it => it.name === name)
    .flatMap(it => asArray(it))
    .flatMap(it => getGarmentCompositionText(it.value))
    .filter(notUndefined)
    .map(it => it[language])
}

export function marshalledModelDetail(
  attributesList: ModelDetailAttribute[],
  attributeName: "maleModel" | "femaleModel"
): Option<ModelDetail> {
  if (attributesList.length === 0) {
    return Option.empty()
  }

  return Option.of({
    model: attributeName,
    height:
      centimetresToLength(
        attributesList.find(it => it.name === "modelHeightMetric")
          ?.value as number
      ) || null,
    chest:
      centimetresToLength(
        attributesList.find(it => it.name === "modelChestMeasurement")
          ?.value as number
      ) || null,
    waist:
      centimetresToLength(
        attributesList.find(it => it.name === "modelWaistMeasurement")
          ?.value as number
      ) || null,
    size:
      (attributesList.find(it => it.name === "modelWearingSize")
        ?.value as string) || null
  })
}

export const extractModelDetail = (
  attributeName: "maleModel" | "femaleModel",
  attributes: Attribute[]
): Option<ModelDetail> => {
  const attributesList =
    attributeValues<ModelDetailAttribute>(attributeName, attributes) || []
  return marshalledModelDetail(attributesList, attributeName)
}

export const isKeyAndLabel = (obj: any): obj is KeyAndLabel => {
  return !!obj?.key && !!obj?.label && isLocalizedString(obj.label)
}

const isLocalizedString = (obj: any): obj is LocalizedString => {
  return _isObject(obj) && (!!obj[Language.en] || !!obj[Language.is])
}

export const asArray = <T>(value: T | T[]): T[] => {
  if (_isArray(value)) return value
  else return [value]
}

export const nonEmpty = (value: string) => value.length > 0
